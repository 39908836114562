import React from "react"
import { Modal, Button } from 'react-bootstrap'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { Preview } from './_preview'
import { celebrate } from './_celebrate'
import filesize from 'filesize'

import { API_PATH, API_DIRECT_UPLOAD_INFO } from '../../const/api_paths'
import {
  saveUploadInfo
} from '../../actions/UploadAction'

class UploadModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {...this.defaultData()}
  }

  render() {
    return(
      <Modal {...this.props} onHide={this.onClose} className="custom-modal">
        {this.header()}
        <Modal.Body>
          {this.dragArea()}
          {this.additionalInfo()}
        </Modal.Body>
        {this.footer()}
      </Modal>
    )
  }

  dragArea() {
    return(
      <Dropzone
        getUploadParams={this.getUploadParams}
        onChangeStatus={this.handleChangeStatus}
        onSubmit={this.handleSubmit}
        accept="*"
        canRemove={false}
        multiple={false}
        submitButtonContent=""
        submitButtonDisabled={true}
        maxFiles={1}
        SubmitButtonComponent={props => null}
        PreviewComponent={Preview}
      />
    )
  }

  handleSubmit = (files, allFiles) => {
    console.log('handleSubmit')
    // console.log(files)
    // console.log(all_files)
  }

  handleChangeStatus = (meta, status) => {
    // console.log('handleChangeStatus')
    // console.log(meta)
    // console.log(status)

    if(status === 'done') {
      this.saveCompleteUpload()
      celebrate()
      setTimeout(()=>{celebrate()}, 2000)
      setTimeout(()=>{celebrate()}, 4000)
    }
  }

  saveCompleteUpload() {
    var data = {
      file_uuid: this.state.file_uuid,
      upload_status: 'completed'
    }

    saveUploadInfo(data, {
      success: (res) => {},
      error: (res) => {}
    })
  }

  getUploadParams = async (d) => {
    var data = {
      file_name: d.file.name,
      file_type: d.file.type,
      file_size: d.file.size,
    }

    var token = localStorage.getItem('authToken')
    var headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Bearer ${token}`)

    this.setState(data)
    var options = {
      method:   "POST",
      mode:     "cors",
      cache:    "no-cache",
      headers:  headers,
      body:     JSON.stringify(data)
    }

    var url_option = await fetch(API_PATH(API_DIRECT_UPLOAD_INFO), options)
    .then(res => res.json())
    .then(res=> {
      this.setState({file_uuid: res.file_uuid})
      return {
        method: 'put',
        url: res.signedUrl,
        body: d.file
      }
    })
    return url_option
  }

  additionalInfo() {
    return(
      <div className="video-add-info">
        <h4>Add Information about the File:</h4>
        {this.fileInfo()}
        {this.elemText("easy_name",               "Easy Name")}
        {this.elemText("description",             "Description")}
        {this.elemText("video_type",              "Video Type")}
        {this.elemText("author",                  "Author")}
        {this.elemDateTime("video_recorded_date", "Video Recorded Date")}
        {this.elemText("video_location",          "Video Location")}
        {this.elemText("keywords",                "Keywords")}
        {this.elemText("people_names",            "People Names")}
        {this.elemText("link_path_1",             "URL 1")}
        {this.elemText("link_path_2",             "URL 2")}
        {this.elemText("computer_path_1",         "Computer #1 Path")}
        {this.elemText("computer_path_2",         "Computer #2 Path")}
        {this.elemText("external_hdd_1",          "ExtHDD #1 Path")}
        {this.elemText("external_hdd_2",          "ExtHDD #2 Path")}
        {this.elemText("external_hdd_3",          "ExtHDD #3 Path")}
        {this.elemText("other_path_1",            "Other Path 1")}
        {this.elemText("other_path_2",            "Other Path 2")}
      </div>
    )
  }

  fileInfo() {
    if(this.state.file_name !== '') {
      return(
        <div className="mb-2 alert alert-secondary">
          <label className="text-primary d-block">{this.state.file_name} - </label>
          <small>{filesize(this.state.file_size, {base: 10})}</small>
        </div>
      )
    }
  }

  header() {
    return(
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
    )
  }

  footer() {
    return(
      <Modal.Footer>
        <div className="d-flex flex-column">
          {this.saveButton()}
          <div className="text-center">
            <button className="btn btn-light btn-sm mt-2 px-3" onClick={this.onClose}>
              CLOSE
            </button>
          </div>
        </div>
      </Modal.Footer>
    )
  }

  saveButton() {
    if(this.state.file_uuid !== '') {
      return <Button variant="primary" className="px-5" onClick={this.onSave}>SAVE FILE INFORMATION</Button>
    } else {
      return <button className="btn btn-md btn-primary px-5" disabled>SAVE FILE INFORMATION</button>
    }
  }

  elemText(name, label) {
    return(
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          className="form-control"
          id={name}
          name={name}
          value={this.state[name]}
          onChange={this.onChange}
        />
      </div>
    )
  }

  elemDateTime(name, label) {
    return(
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="date"
          className="form-control"
          id={name}
          name={name}
          value={this.state[name]}
          onChange={this.onChange}
        />
      </div>
    )
  }

  onChange = (e) => {
    var data = {}
    data[e.target.name] = e.target.value
    this.setState(data)
  }

  onSave = (e) => {
    var data = {...this.state}
    saveUploadInfo(data, {
      success: (res) => {
        alert("Successfully updated settings")
        // this.onClose(null)
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

  onClose = (e) => {
    this.setState({...this.defaultData()})
    this.props.onClose()
  }

  defaultData() {
    return {
      file_uuid:            '',
      easy_name:            '',
      description:          '',
      video_type:           '',
      author:               '',
      video_recorded_date:  '',
      video_location:       '',
      keywords:             '',
      people_names:         '',
      file_name:            '',
      file_type:            '',
      file_size:            '',
      link_path_1:          '',
      link_path_2:          '',
      computer_path_1:      '',
      computer_path_2:      '',
      external_hdd_1:       '',
      external_hdd_2:       '',
      external_hdd_3:       '',
      other_path_1:         '',
      other_path_2:         '',
    }
  }

}

UploadModal.propTypes = {}
export default UploadModal