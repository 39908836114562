import React from "react"
import PropTypes from "prop-types"

class FileSearch extends React.Component {

  constructor(props) {
    super(props)
    this.state = {...this.defaultData()}
  }

  render() {
    return(
      <div className="filter-wrappper">
        <div className="filter-fields">
          {this.elemText('file_name', 'File Name')}
          {this.elemText('easy_name', 'Easy Name')}
          {this.elemText('description', 'Description')}
          {this.elemText('video_type', 'File Type')}
          {this.elemText('keywords', 'Keywords')}
          {this.elemText('people_names', 'People Names')}
        </div>
        <div className="filter-actions">
          <button className="btn btn-warning" onClick={this.applyFilter}>Apply Filter</button>
          <button className="btn btn-secondary ml-3" onClick={this.clearFilter}>Clear</button>
        </div>
      </div>
    )
  }

  elemText(name, label) {
    return(
      <div>
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          className="form-control"
          id={name}
          name={name}
          value={this.state[name]}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
        />
      </div>
    )
  }

  onChange = (e) => {
    var data = {}
    data[e.target.name] = e.target.value
    this.setState(data)
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.applyFilter()
    }
  }

  defaultData() {
    return {
      file_name:            '',
      easy_name:            '',
      description:          '',
      video_type:           '',
      author:               '',
      video_recorded_date:  '',
      video_location:       '',
      keywords:             '',
      people_names:         '',
    }
  }

  clearFilter = () => {
    this.setState({...this.defaultData()})
    this.props.onApply({})
  }

  applyFilter = () => {
    this.props.onApply(this.cleanObject({...this.state}))
  }

  cleanObject(obj) {
    Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === '') && delete obj[key])
    return obj
  }

}

FileSearch.propTypes = {
  onApply: PropTypes.func.isRequired
}
export default FileSearch