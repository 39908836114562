import {
  doAuthenticatedPost
} from './_methods'

import {
  API_FILE_INFO,
  API_UPLOAD_FILES,
  API_DOWNLOAD_INFO,
} from '../const/api_paths'

export const getUploadFiles = (filters, callback) => {
  doAuthenticatedPost(API_UPLOAD_FILES, filters, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const getDownloadInfo = (data, callback) => {
  doAuthenticatedPost(API_DOWNLOAD_INFO, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const getFileInfo = (data, callback) => {
  doAuthenticatedPost(API_FILE_INFO, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}