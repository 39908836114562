import React from "react"
import { Modal, Button } from 'react-bootstrap'
import { celebrate } from './_celebrate'
import {
  saveUploadInfo
} from '../../actions/UploadAction'

class FileUploadModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {...props.data}
  }

  render() {
    return(
      <Modal {...this.props} onHide={this.onClose} className="custom-modal">
        {this.header()}
        <Modal.Body>
          {this.additionalInfo()}
        </Modal.Body>
        {this.footer()}
      </Modal>
    )
  }

  additionalInfo() {
    return(
      <div className="video-add-info">
        <h4>Add Information about the File:</h4>
        {this.elemText("easy_name",               "Easy Name")}
        {this.elemText("description",             "Description")}
        {this.elemText("video_type",              "Video Type")}
        {this.elemText("author",                  "Author")}
        {this.elemDateTime("video_recorded_date", "Video Recorded Date")}
        {this.elemText("video_location",          "Video Location")}
        {this.elemText("keywords",                "Keywords")}
        {this.elemText("people_names",            "People Names")}
        {this.elemText("link_path_1",             "URL 1")}
        {this.elemText("link_path_2",             "URL 2")}
        {this.elemText("computer_path_1",         "Computer #1 Path")}
        {this.elemText("computer_path_2",         "Computer #2 Path")}
        {this.elemText("external_hdd_1",          "ExtHDD #1 Path")}
        {this.elemText("external_hdd_2",          "ExtHDD #2 Path")}
        {this.elemText("external_hdd_3",          "ExtHDD #3 Path")}
        {this.elemText("other_path_1",            "Other Path 1")}
        {this.elemText("other_path_2",            "Other Path 2")}
      </div>
    )
  }

  header() {
    return(
      <Modal.Header closeButton>
        <Modal.Title>Edit File</Modal.Title>
      </Modal.Header>
    )
  }

  footer() {
    return(
      <Modal.Footer>
        <div className="d-flex flex-column">
          {this.saveButton()}
          <div className="text-center">
            <button className="btn btn-light btn-sm mt-2 px-3" onClick={this.onClose}>
              CLOSE
            </button>
          </div>
        </div>
      </Modal.Footer>
    )
  }

  saveButton() {
    if(this.state.file_uuid !== '') {
      return <Button variant="primary" className="px-5" onClick={this.onSave}>SAVE FILE INFORMATION</Button>
    } else {
      return <button className="btn btn-md btn-primary px-5" disabled>SAVE FILE INFORMATION</button>
    }
  }

  elemText(name, label) {
    return(
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          className="form-control"
          id={name}
          name={name}
          value={this.state[name]}
          onChange={this.onChange}
        />
      </div>
    )
  }

  elemDateTime(name, label) {
    return(
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="date"
          className="form-control"
          id={name}
          name={name}
          value={this.state[name]}
          onChange={this.onChange}
        />
      </div>
    )
  }

  onChange = (e) => {
    var data = {}
    data[e.target.name] = e.target.value
    this.setState(data)
  }

  onSave = (e) => {
    var data = this.targetData()
    saveUploadInfo(data, {
      success: (res) => {
        celebrate()
        alert("Successfully updated file info")
        this.props.onNewData(res.data)
        this.onClose(null)
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

  onClose = (e) => {
    this.props.onClose()
  }

  targetData() {
    return {
      file_uuid:            this.state.uuid,
      easy_name:            this.state.easy_name,
      description:          this.state.description,
      video_type:           this.state.video_type,
      author:               this.state.author,
      video_recorded_date:  this.state.video_recorded_date,
      video_location:       this.state.video_location,
      keywords:             this.state.keywords,
      people_names:         this.state.people_names,
      file_name:            this.state.file_name,
      file_type:            this.state.file_type,
      link_path_1:          this.state.link_path_1,
      link_path_2:          this.state.link_path_2,
      computer_path_1:      this.state.computer_path_1,
      computer_path_2:      this.state.computer_path_2,
      external_hdd_1:       this.state.external_hdd_1,
      external_hdd_2:       this.state.external_hdd_2,
      external_hdd_3:       this.state.external_hdd_3,
      other_path_1:         this.state.other_path_1,
      other_path_2:         this.state.other_path_2,
    }
  }

}

FileUploadModal.propTypes = {}
export default FileUploadModal