import React from "react"
import { Router } from "@reach/router"

// import { Link } from "gatsby"
import LayoutApp        from "../components/layout_app"
import { hasAuthToken } from '../actions/_auth'
import Dashboard        from '../components/app_pages/dashboard'
import Settings         from '../components/app_pages/settings'
import Upload           from '../components/app_pages/upload'
import Files            from '../components/app_pages/files'
import FileInfo         from '../components/app_pages/file_info'
import FileRequests     from '../components/app_pages/file_requests'

class AppPage extends React.Component {

  componentDidMount() {
    if(hasAuthToken() === false) {
      window.location = '/auth/signin'
    } else {
      // get info from server
    }
  }

  render() {
    return(
      <LayoutApp>
        <Router basepath="app">
          <Settings path="settings" />
          <FileInfo path="files/:uuid" />
          <Files path="files" />
          <FileRequests path="requests" />
          <Upload path="upload" />
          <Dashboard path="/" />
        </Router>
      </LayoutApp>
    )
  }
}

AppPage.propTypes = {}
export default AppPage
