import React from "react"
import UploadModal from "./upload_modal"


class QuickUpload extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      modal_show: false
    }
  }

  render() {
    return(
      <div className="quick_upload box-border">
        <h3>Upload File</h3>
        <form>
          <div className="custom-file mb-3">
            <input type="file" className="custom-file-input" id="customFile" onClick={this.openModal} />
            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
          </div>
          <div>
            <button className="btn btn-md btn-primary" onClick={this.openModal}>UPLOAD FILE</button>
          </div>
          <UploadModal
            show={this.state.modal_show}
            onClose={this.handleClose}
            keyboard={false}
            backdrop={'static'}
          />
        </form>

      </div>
    )
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({modal_show: true})
  }

  handleClose = () => {
    this.setState({modal_show: false})
  }

}

QuickUpload.propTypes = {}
export default QuickUpload