import React from "react"

class ShortAnalytics extends React.Component {

  render() {
    return(
      <div className="short_analytics box-border" id="short_analytics">
        <h3>Analytics</h3>
        <ul>
          <li><label htmlFor="short_analytics">Total Files:</label> {this.props.total_files}</li>
          <li><label htmlFor="short_analytics">Total File Size:</label> {this.props.total_file_size}</li>
          <li><label htmlFor="short_analytics">Total Projects:</label> {this.props.total_projects}</li>
        </ul>
      </div>
    )
  }

}

ShortAnalytics.propTypes = {}
export default ShortAnalytics