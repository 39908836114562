import React from "react"
// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import moment from 'moment'

class VideoCard extends React.Component {

  render() {
    return(
      <div className="latest-video box-border">
        {this.cardTitle()}
        {this.easyName()}
        {this.imageThumbnail()}
        {this.fileName()}
        {this.fileInfos()}
      </div>
    )
  }

  cardTitle() {
    if(this.props.title) {
      return <h3>{this.props.title}</h3>
    }
  }

  imageThumbnail() {
    if(this.props.thumb_url) {
      return(
        <img
          src={this.props.thumb_url}
          className="video-thumb"
          alt={`Thumbnail ${this.props.easy_name}`}
        />
      )
    }
  }

  easyName() {
    if(this.props.easy_name && this.props.easy_name !== this.props.filename) {
      return <h4>{this.props.easy_name}</h4>
    }
  }

  fileName() {
    return <h4>{this.props.file_name}</h4>
  }

  fileInfos() {
    if(this.props.infos) {
      return(
        <ul>
          {this.props.infos.map(i=>this.infoRow(i))}
        </ul>
      )
    }
  }

  infoRow(info) {
    var value = info.value
    if(info.label === 'Upload Date') {
      value = moment(value).fromNow()
    }
    return <li key={`info_${Math.random()}`}><label>{info.label}:</label> {value}</li>
  }

}

VideoCard.propTypes = {}
export default VideoCard