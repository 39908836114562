import {
  doAuthenticatedPost
} from './_methods'

import {
  API_REQUEST_FILES,
  API_REQUEST_FILE_CREATE,
  API_REQUEST_FILE_UPDATE_STATUS
} from '../const/api_paths'

export const getRequestFiles = (filters, callback) => {
  doAuthenticatedPost(API_REQUEST_FILES, filters, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const createRequestFiles = (data, callback) => {
  doAuthenticatedPost(API_REQUEST_FILE_CREATE, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const updateRequestFileStatus = (data, callback) => {
  doAuthenticatedPost(API_REQUEST_FILE_UPDATE_STATUS, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

