import React from "react"
import { Link } from "gatsby"

class Sidemenu extends React.Component {

  render() {
    return(
      <div className="left-side">
        <ul>
          <li><Link to="/app/">Dashboard</Link></li>
          <li><Link to="/app/files/">All My Files</Link></li>
          <li><Link to="/app/requests/">Request For Files</Link></li>
          <li><Link to="/">My Projects</Link></li>
          <li><Link to="/">My Video Files</Link></li>
          <li><Link to="/app/upload/">Upload File</Link></li>
          {/* <li><Link to="/">Privacy Settings</Link></li> */}
          <li><Link to="/app/settings/">Settings</Link></li>
          <li><Link to="/auth/signout">Logout</Link></li>
        </ul>
      </div>
    )
  }

}

Sidemenu.propTypes = {}
export default Sidemenu