import {
  doAuthenticatedPost
} from './_methods'

import {
  API_SETTINGS_INFO,
  API_SETTINGS_UPDATE
} from '../const/api_paths'

export const getSettingsInfo = (callback) => {
  doAuthenticatedPost(API_SETTINGS_INFO, {}, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const saveSettings = (data, callback) => {
  doAuthenticatedPost(API_SETTINGS_UPDATE, data, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}