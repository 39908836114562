import React from "react"
import { Link } from "gatsby"

class RecentFiles extends React.Component {

  render() {
    return(
      <div className="recent_files box-border">
        <h3>Recent Files</h3>
        {this.fileList()}
      </div>
    )
  }

  fileList() {
    return(
      <ul>
        {this.props.recent_files.map(f=>this.fileRow(f))}
      </ul>
    )
  }

  fileRow(file) {
    return(
      <li key={`recent_file_${Math.random()}`}>
        <Link to={`/app/files/${file.uuid}`}>{file.file_name}</Link>
      </li>
    )
  }

}

RecentFiles.propTypes = {}
export default RecentFiles