import React from "react"
import { Link } from "gatsby"
import SEO from "../seo"
import FileSearch from '../app/file_search'
import {
  getUploadFiles,
  getDownloadInfo,
} from '../../actions/FilesAction'

class Files extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      filters: {},
      upload_files: [],
    }
  }

  componentDidMount() {
    this.doFilter(this.state.filters)
  }

  render() {
    return(
      <>
        <SEO title="All Files" />
        <h4 className="text-primary">All My Files</h4>
        <FileSearch onApply={this.onApplyFilter} />
        {this.uploadFiles(this.state.upload_files)}
        {this.hiddenDownloadFrame()}
      </>
    )
  }

  uploadFiles(files) {
    return(
      <table className="table table-bordered mt-4">
        <thead className="table-primary">
          <tr>
            <th>Filename</th>
            <th>Description</th>
            <th>File Type</th>
            <th>Infos</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {files.map(file => this.fileRow(file))}
        </tbody>
      </table>
    )
  }

  fileRow(file) {
    return(
      <tr key={`file_row-${file.uuid}`}>
        <td>
          <Link to={`/app/files/${file.uuid}`}>{file.file_name}</Link>
          <div>{file.easy_name}</div>
          <div>{file.file_size}</div>
        </td>
        <td><pre>{file.description}</pre></td>
        <td>{file.video_type}</td>
        <td>
          {this.displayBadge(file.keywords)}
          {this.displayBadge(file.people_names)}
        </td>
        <td>
          <Link to={`/app/files/${file.uuid}`} className="btn btn-sm btn-primary mr-2">View</Link>
          <button className="btn btn-sm btn-warning" onClick={()=>this.doDownload(file)}>Download</button>
        </td>
      </tr>
    )
  }

  displayBadge(data) {
    if(data) {
      return data.split(",").map(i=>{
        return <span className="badge badge-info m-1">{i.trim()}</span>
      })
    }
  }

  hiddenDownloadFrame() {
    return(
      <iframe
        id="dl_iframe"
        title="DL"
        className="d-none"
      ></iframe>
    )
  }

  doDownload = (file) => {
    var data = {file_uuid: file.uuid}
    getDownloadInfo(data, {
      success: (res) => {
        document.getElementById('dl_iframe').src = res.signedUrl
      },
      error: (res) => {
        alert(res)
      }
    })
  }

  onApplyFilter = (filters) => {
    this.doFilter(filters)
    this.setState(filters)
  }

  doFilter = (filters) => {
    getUploadFiles(filters, {
      success: (res) => {
        this.setState({
          upload_files: res.upload_files
        })
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

}

Files.propTypes = {}
export default Files
