import {
  doAuthenticatedPost
} from './_methods'

import {
  API_DASHBOARD_INFO
} from '../const/api_paths'

export const getDashboardInfo = (callback) => {
  doAuthenticatedPost(API_DASHBOARD_INFO, {}, {
    success:  (res) => callback.success(res),
    error:    (res) => callback.error(res.error),
    catch:    (res) => callback.error('Oops... Something is wrong, please try again')
  })
}

export const prepLatestVideo = (data) => {
  if(!data) {
    return null
  }

  var new_latest_video = {
    uuid:       data.uuid,
    easy_name:  data.easy_name,
    file_name:  data.file_name,
    thumb_url:  null,
    // thumb_url:  "https://placeimg.com/640/480/nature",
  }

  new_latest_video.infos = []
  if(data.file_size)        { new_latest_video.infos.push({label: "File Size", value: data.file_size}) }
  if(data.file_type)        { new_latest_video.infos.push({label: "File Type", value: data.file_type}) }
  if(data.author)           { new_latest_video.infos.push({label: "Author", value: data.author}) }
  if(data.video_type)       { new_latest_video.infos.push({label: "Video Type", value: data.video_type}) }
  if(data.created_date)     { new_latest_video.infos.push({label: "Date Recorded", value: data.created_date}) }
  if(data.created_location) { new_latest_video.infos.push({label: "Video Location", value: data.created_location}) }
  if(data.inserted_at)      { new_latest_video.infos.push({label: "Upload Date", value: data.inserted_at}) }

  return new_latest_video
}