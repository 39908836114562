import React        from "react"
import SEO          from "../seo"
import QuickUpload  from "../app/quick_upload"

class AppPage extends React.Component {

  render() {
    return(
      <>
        <SEO title="Upload File" />
        <div className="dashboard-content">
          <QuickUpload />
        </div>
      </>
    )
  }

}

AppPage.propTypes = {}
export default AppPage