import React from "react"
// import { Link } from "gatsby"
import SEO from "../seo"
import FileUploadModal from '../app/file_update_modal'
import {
  getFileInfo,
  getDownloadInfo
} from '../../actions/FilesAction'

class FileInfo extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modal_show: false,
      data: {}
    }
  }

  componentDidMount() {
    getFileInfo({uuid: this.props.uuid}, {
      success: (res) => {
        this.setState({
          loading: false,
          data: res.data
        })
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

  render() {
    return(
      <>
        <SEO title="File Info" />
        {this.loadedView()}
        {this.loading()}
      </>
    )
  }

  loading() {
    return(
      <div>loading...</div>
    )
  }

  loadedView() {
    if(!this.state.loading) {
      return(
        <>
        {this.headingInfo()}
          <hr/>
          <table className="table table-bordered">
            <tbody>
              {this.infoDataStructure().map(i=> this.infoRow(i))}
            </tbody>
          </table>
          {this.hiddenDownloadFrame()}
          <FileUploadModal
            show={this.state.modal_show}
            onClose={this.handleClose}
            keyboard={false}
            backdrop={'static'}
            data={{...this.state.data}}
            onNewData={(data)=>this.setState({data})}
          />
        </>
      )
    }
  }

  headingInfo() {
    return(
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="text-primary">{this.state.data.file_name}</h4>
          <h5>{this.state.data.easy_name}</h5>
        </div>
        <div>
          <button className="btn btn-md btn-secondary mr-3" onClick={()=>this.setState({modal_show: true})}>Edit</button>
          <button className="btn btn-md btn-warning" onClick={()=>this.doDownload(this.state.data.uuid)}>Download</button>
        </div>
      </div>
    )
  }

  hiddenDownloadFrame() {
    return(
      <iframe
        id="dl_iframe"
        title="DL"
        className="d-none"
      ></iframe>
    )
  }

  doDownload = (uuid) => {
    var data = {file_uuid: uuid}
    getDownloadInfo(data, {
      success: (res) => {
        document.getElementById('dl_iframe').src = res.signedUrl
      },
      error: (res) => {
        alert(res)
      }
    })
  }

  infoRow(info) {
    return(
      <tr key={`row_${info[0]}`}>
        <th>{info[1]}</th>
        <td>{this.state.data[info[0]]}</td>
      </tr>
    )
  }

  infoDataStructure() {
    return [
      ["upload_status",      "Is Uploaded"],
      ["file_size",          "File Size"],
      ["file_type",          "File Type"],
      ["easy_name",          "Easy Name"],
      ["video_type",         "Video Type"],
      ["author",             "Author"],
      ["created_date",       "Date Created"],
      ["created_location",   "Location"],
      ["description",        "Description"],
      ["keywords",           "Keywords"],
      ["people_names",       "People Names"],
      ["link_path_1",        "Link Path 1"],
      ["link_path_2",        "Link Path 2"],
      ["computer_path_1",    "Computer Path 1"],
      ["computer_path_2",    "Computer Path 2"],
      ["external_hdd_1",     "External HDD 1"],
      ["external_hdd_2",     "External HDD 2"],
      ["external_hdd_3",     "External HDD 3"],
      ["other_path_1",       "Other Path 1"],
      ["other_path_2",       "Other Path 2"],
    ]
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({modal_show: true})
  }

  handleClose = () => {
    this.setState({modal_show: false})
  }

}

FileInfo.propTypes = {}
export default FileInfo
