import React from "react"
// import { Link } from "gatsby"
import SEO            from "../seo"
import VideoCard      from "../app/video_card"
import ShortAnalytics from "../app/short_analytics"
import QuickUpload    from "../app/quick_upload"
import RecentFiles    from "../app/recent_files"

import {
  getDashboardInfo,
  prepLatestVideo
} from '../../actions/DashboardAction'

class AppPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      latest_video: {
        easy_name:  "",
        file_name:  "",
        thumb_url:  null,
        infos: []
      },
      recent_files: [],
      analytics: {
        total_files: '',
        total_file_size: '',
        total_projects: ''
      }
    }
  }

  componentDidMount() {
    getDashboardInfo({
      success: (res) => {
        this.setState({
          latest_video: prepLatestVideo(res.latest_video),
          recent_files: res.recent_files,
          analytics: res.analytics
        })
      },
      error: (res) => {
        alert('please try again')
      }
    })
  }

  render() {
    return(
      <>
        <SEO title="Dashboard" />
        <div className="dashboard-content">
          {this.videoCard()}
          <div>
            <ShortAnalytics {...this.state.analytics} />
            <QuickUpload />
          </div>
          <RecentFiles recent_files={this.state.recent_files} />
        </div>
      </>
    )
  }

  videoCard() {
    if(this.state.latest_video) {
      return <VideoCard title="Latest Video" {...this.state.latest_video} />
    }
  }
}

AppPage.propTypes = {}
export default AppPage
