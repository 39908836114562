import React from "react"
// import { Link } from "gatsby"
import SEO from "../seo"
import {
  getSettingsInfo,
  saveSettings
} from '../../actions/SettingsAction'

class Settings extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading:        true,
      s3_bucket_name: '',
      s3_region:      '',
      s3_access_key:  '',
      s3_secret_key:  '',
    }
  }

  componentDidMount() {
    getSettingsInfo({
      success: (res) => {
        this.setState({...res, loading: false})
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

  render() {
    return(
      <>
        <SEO title="Settings" />
        {this.settingsForm()}
      </>
    )
  }


  settingsForm() {
    if(this.state.loading) {
      return <div>loading ...</div>
    }

    return(
      <div className="form">
        <h2 className="mb-4">Settings</h2>
        {this.elemText("s3_bucket_name",  "S3 Bucket Name")}
        {this.elemText("s3_region",       "S3 Region")}
        {this.elemText("s3_access_key",   "S3 Access Key")}
        {this.elemText("s3_secret_key",   "S3 Secret Access Key")}
        {this.buttons()}
      </div>
    )
  }

  buttons() {
    return(
      <div className="form-group mt-4">
        <button className="btn btn-md btn-primary" onClick={this.doSave}>Save Settings</button>
      </div>
    )
  }

  elemText(name, label) {
    return(
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          className="form-control"
          id={name}
          name={name}
          value={this.state[name]}
          onChange={this.onChange}
        />
      </div>
    )
  }

  onChange = (e) => {
    var data = {}
    data[e.target.name] = e.target.value
    this.setState(data)
  }

  doSave = (e) => {
    var data = {...this.state}
    saveSettings(data, {
      success: (res) => {
        alert("Successfully updated settings")
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

}

Settings.propTypes = {}
export default Settings
