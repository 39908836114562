import React from "react"
import SEO from "../seo"
import _ from 'lodash'
import { celebrate, fireworks } from '../app/_celebrate'
import {
  getRequestFiles,
  createRequestFiles,
  updateRequestFileStatus
} from '../../actions/FileRequestsAction'

class FileRequests extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      filters: {},
      file_requests: [],
    }
  }

  componentDidMount() {
    this.loadRequests()
  }

  render() {
    return(
      <>
        <SEO title="Request for Files" />
        <div className="d-flex justify-content-between">
          <h4 className="text-primary">Requests for Files</h4>
          {this.createButton()}
        </div>
        {this.fileRequests(this.state.file_requests)}
      </>
    )
  }

  createButton() {
    return(
      <button className="btn btn-md btn-primary" onClick={this.createNewRequest}>
        Create New Request
      </button>
    )
  }

  fileRequests(file_requests) {
    return(
      <table className="table table-bordered mt-4">
        <thead className="table-primary">
          <tr>
            <th>URL</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {file_requests.map(file_request => this.requestRow(file_request))}
        </tbody>
      </table>
    )
  }

  requestRow(file_request) {
    return(
      <tr>
        <td>
          <input
            type="text"
            className="form-control"
            value={`https://harddisk.cloud/requests/${file_request.uuid}`}
            readOnly
          />
        </td>
        <td>{this.statusBadge(file_request)}</td>
        <td>{this.enableButton(file_request)}</td>
      </tr>
    )
  }

  createNewRequest = (e) => {
    createRequestFiles({},{
      success: (res) => {
        // alert("Successfully created a new Request")
        celebrate()
        this.loadRequests()
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

  loadRequests = () => {
    getRequestFiles(this.state.filters, {
      success: (res) => {
        this.setState({
          file_requests: res.file_requests
        })
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

  statusBadge(file_request) {
    if(file_request.status === 'active') {
      return(<span class="badge badge-pill badge-success">active</span>)
    } else {
      return(<span class="badge badge-pill badge-secondary">inactive</span>)
    }
  }

  enableButton(file_request) {
    if(file_request.status === 'active') {
      return(
        <button
          className="btn btn-sm btn-secondary"
          onClick={()=>this.onDisable(file_request)}
        >Deactivate</button>
      )
    } else {
      return(
        <button
          className="btn btn-sm btn-primary"
          onClick={()=>this.onEnable(file_request)}
        >Activate</button>
      )
    }
  }

  onEnable = (file_request) => {
    this.updateStatus(file_request, 'active', celebrate)
  }

  onDisable = (file_request) => {
    this.updateStatus(file_request, 'inactive', fireworks)
  }

  updateStatus = (file_request, status, callback) => {
    var data = {
      file_request_uuid: file_request.uuid,
      status: status
    }

    updateRequestFileStatus(data, {
      success: (res) => {
        var file_requests = [...this.state.file_requests]
        var target = _.find(file_requests, (i)=>{return i.uuid === file_request.uuid})
        target.status = data.status
        this.setState(file_requests)
        callback()
      },
      error: (res) => {
        alert("Please try again...")
      }
    })
  }

}

FileRequests.propTypes = {}
export default FileRequests
