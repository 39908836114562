import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Sidemenu from "./_common/sidemenu"
import 'bootstrap/dist/css/bootstrap.min.css'
import "../assets/css/styles"

const LayoutFull = ({ children }) => {
  return (
    <>
      <Header siteTitle="harddisk.cloud" />
      <div id="content">
        <Sidemenu />
        <main>{children}</main>
      </div>
      <footer>© harddiskcloud - your harddisk in the cloud</footer>
    </>
  )
}

LayoutFull.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutFull